import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { BSBtnDropdown } from "traec-react/utils/bootstrap";

import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";
import Moment from "moment";
import { setMetricScore, setUIItems } from "traec/redux/actionCreators";
import chroma from "chroma-js";
import TextareaAutosize from "react-textarea-autosize";
import { getNodeFromPath, getParentNodeFromPath, getPathChildren } from "traec/utils/nodes";
import { getConversionFactor, MetricAlerts, RequiredStar } from "../metrics/metricRow";

import RowErrorBoundary, { MiniErrorBoundary, CellSpanErrorBoundary } from "./error";
import { SubNodes } from "./node";

import { getLatestValue, get_conversion, hasDiff } from "./utils";
import SearchChildRow from "./search";
import SelectChildRow from "./selectChildren";
import SelectTwinChildRow from "./selectTwinChildren";
import { setAndShowModal, clearModal } from "AppSrc/utils/modal";

import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "traec-react/errors/handleError";

import ChartJSWrapper from "AppSrc/charts/chartjs";
import { getAverage, check100PercentDifference } from "storybook-dashboard/utils/index";
import { roundDecimals } from "storybook-dashboard/dashboard/charts/utils";
import { current_path } from "../metrics/dragHandle/grabber";

const isNullOrUndefined = (value) => {
  return value === null || value === undefined;
};

function DisabledInput({ value }) {
  return (
    <input
      type="number"
      disabled={true}
      className={`form-control-plaintext m-0 p-0`}
      name={"value"}
      value={value === null ? "" : value}
      style={{
        backgroundColor: "#e9ecef",
      }}
    />
  );
}

export function ReportNoInputCheckbox({ hide, calculated, disableInputs, noReport, handleNoReportChange }) {
  if (hide) {
    return null;
  }
  let isDisabled = disableInputs || calculated;
  return (
    <td className="text-center border-0">
      <input
        type="checkbox"
        disabled={isDisabled}
        className={`m-0 p-0`}
        checked={noReport}
        onChange={handleNoReportChange}
        name={"noreport"}
      />
    </td>
  );
}

export function ReportValueInputCell(props) {
  let {
    hide,
    calculated,
    isValid,
    disableInputs,
    disableValueInput,
    value,
    handleInputChange,
    handleBlur,
    score,
    commit,
    tracker,
  } = props;

  if (hide) {
    return null;
  }

  if (calculated || disableInputs || (disableInputs && !value)) {
    return (
      <td className="border-0">
        <DisabledInput value={value} />
      </td>
    );
  }

  const [largeDifferenceReported, setLargeDifferenceReported] = useState(false);
  const [values, setValues] = useState(Traec.Im.List());

  const handleFocus = () => {
    fetchInputHistory({
      baseMetricId: score?.getInPath("metric.uid"),
      commitId: commit?.get("uid"),
      trackerId: tracker.get("uid"),
      setValues,
    });
  };

  const historicValues = values
    ?.map((report) => report?.get("value"))
    ?.filter((val) => typeof val == "number")
    ?.toJS();

  const historicAverage = getAverage(historicValues);

  let invalidFeedback = null;

  if (!isValid && isValid !== undefined) {
    invalidFeedback = <div className="invalid-feedback">Number required</div>;
  }

  const currentValueVarianceFromHistoric = (e) => {
    e.preventDefault();
    //  Check current value is 100% larger or smaller than historic average
    if (
      e.target.value &&
      historicValues?.length > 0 &&
      e.target.value !== 0 &&
      !(historicAverage < 20 && e.target.value < 20) &&
      check100PercentDifference(historicAverage, parseFloat(e.target.value))
    ) {
      setLargeDifferenceReported(true);
    } else {
      setLargeDifferenceReported(false);
    }
  };

  const valueVarianceWarning = (
    <Tooltip
      style={{ cursor: "pointer" }}
      position="bottom"
      html={
        <div>
          <u>Alert</u>: this value is significantly different to the average:{" "}
          <u>{`${roundDecimals(historicAverage)}`}</u> of the previous
          {` ${historicValues?.length}`} entries.
          <br /> Please check before submitting.
        </div>
      }
    >
      <Octicon name="alert" className="text-warning mt-1 mr-1" />
      <span class="badge badge-warning m-0">Check</span>
    </Tooltip>
  );

  const handleBlurCombined = (e) => {
    if (handleBlur) handleBlur(e);
    currentValueVarianceFromHistoric(e);
  };

  return (
    <td className="border-0">
      <input
        disabled={calculated || disableValueInput}
        style={{ height: "auto", display: "inline-block" }}
        className={`form-control m-0 p-0 ${invalidFeedback ? "is-invalid" : null} ${
          largeDifferenceReported ? "border-warning" : null
        }`}
        name={"value"}
        value={value === null ? "" : value}
        onChange={handleInputChange}
        onBlur={handleBlurCombined}
        onFocus={handleFocus}
      />
      {invalidFeedback}
      {largeDifferenceReported ? valueVarianceWarning : null}
    </td>
  );
}

const dateToStr = (date, addDays) => {
  let _date = Moment(date);
  _date = _date && addDays ? _date.add(addDays, "days") : _date;
  return _date ? _date.format("Do MMM YY") : "";
};

export function ReportPeriodText({ isRequiredPeriodically, preamble }) {
  if (!isRequiredPeriodically) {
    return null;
  }
  let { from_date, dueDate } = isRequiredPeriodically;
  let _preamble = preamble || "For period from";
  return `${_preamble} ${dateToStr(from_date)} to ${dateToStr(dueDate, -1)}`;
}

function MetricPlaceholderText({ score, isRequiredPeriodically }) {
  if (!score) {
    return "";
  }
  let freqText = "";
  let placeholderText = score.getInPath("meta_json.placeholder") || "";
  if (isRequiredPeriodically) {
    freqText = ReportPeriodText({ isRequiredPeriodically }) || "";
    freqText = placeholderText ? ` (${freqText})` : freqText;
  }
  return placeholderText + freqText;
}

export function ReportCommentCell(props) {
  let { hide, score, comment, calculated, disableInputs, disableValueInput, handleCommentChange, handleBlur, colSpan } =
    props;

  if (hide) {
    return null;
  }

  let disabled = calculated || disableInputs; // ASS-849 & ASS-919 || disableValueInput;

  let CommentInputComponent = calculated ? "input" : TextareaAutosize;
  let placeholder = calculated ? "Calculated from sub-metrics" : MetricPlaceholderText(props) || "";

  let _props = {
    value: calculated ? "" : comment,
    onChange: handleCommentChange,
    onBlur: handleBlur,
  };

  return (
    <td colSpan={colSpan || 1} className="border-0">
      <CommentInputComponent
        type="text"
        className={`form-control-plaintext m-0 p-0`}
        name={"comment"}
        disabled={disabled}
        value={comment}
        placeholder={placeholder}
        {..._props}
      />
    </td>
  );
}

export function ToggleState({ id, labelText, checkedState, state, setState }) {
  return (
    <React.Fragment>
      <input id={id} name={`${id}-state-d`} type="radio" checked={state === checkedState} readOnly={true} />
      <label
        htmlFor={id}
        onClick={(e) => setState(checkedState)}
        className={state === checkedState ? "bg-white text-dark rounded-pill" : ""}
      >
        {labelText}
      </label>
    </React.Fragment>
  );
}

const getBooleanState = (value) => {
  return isNaN(parseFloat(value)) ? null : value > 0;
};

const stateToValue = (state) => {
  return state === null ? null : state == false ? 0 : 1;
};

function BooleanToggleCell({ colSpan, value, handleInputChange, handleBlur, disableInputs }) {
  let state = getBooleanState(value);

  const setState = (value) => {
    if (disableInputs) {
      console.log("Inputs disabled in this report");
      return null;
    }
    handleInputChange({
      target: {
        value: stateToValue(value),
      },
    });
    setTimeout(function () {
      handleBlur({
        preventDefault: () => {
          console.log("BOOLEAN TOGGLE FORCING handleBlur");
        },
      });
    }, 100);
  };

  let _props = {
    state,
    setState,
  };

  // Generate a uuid for the on/na/off ids
  let uid = uuidv4();
  return (
    <td colSpan={colSpan || 1} className="border-0">
      <div className="switch-toggle bg-dark text-white rounded-pill border border-dark">
        <ToggleState id={`${uid}-on`} labelText="Yes" checkedState={true} {..._props} />
        <ToggleState id={`${uid}-na`} labelText="N/A" checkedState={null} {..._props} />
        <ToggleState id={`${uid}-off`} labelText="No" checkedState={false} {..._props} />
        <a></a>
      </div>
    </td>
  );
}

const getRowComponent = (score) => {
  let inputType = score.getInPath("meta_json.input_type") || "standard";

  let InputComponent = ValueCommentRow;
  switch (inputType) {
    case "standard":
      InputComponent = ValueCommentRow;
      break;
    case "boolean":
      InputComponent = ValueCommentRow;
      break;
    case "searchable_children":
      InputComponent = SearchChildrenRow;
      break;
    case "select_children":
      InputComponent = SelectChildrenRow;
      break;
    case "selectable_twin_children":
      InputComponent = SelectTwinChildrenRow;
      break;
    default:
      InputComponent = ValueCommentRow;
  }

  return InputComponent;
};

function MetricName(props) {
  let { tree, score, indentLevel, hasChildren, isCollapsed, onCollapseClick } = props;
  let name = score.getInPath("metric.name");
  let treeId = tree.get("uid");

  let nameComponent = hasChildren ? (
    <a data-toggle="collapse" href={`#${treeId}`} aria-expanded={"false"} onClick={onCollapseClick}>
      <Octicon className="expand_caret" name={isCollapsed ? "triangle-right" : "triangle-down"} />
      {name}
    </a>
  ) : (
    <React.Fragment>
      {/* <Octicon name={"primitive-dot"} /> */}
      {name}
    </React.Fragment>
  );

  return <span style={{ margin: "0", marginLeft: `${indentLevel * 1.5}em` }}>{nameComponent}</span>;
}

function MetricTooltip({ score, isProjectAdmin }) {
  let descr = score.getInPath("metric.description");
  if (!descr) {
    return null;
  }
  return (
    <Tooltip
      animateFill={false}
      html={
        <div className="text-left">
          <div dangerouslySetInnerHTML={{ __html: descr.trim() }} />
          {/*isProjectAdmin ? (
            <p style={{ marginTop: "1rem" }}>
              BaseMetric id: {score ? score.getInPath("metric.uid").substring(0, 8) : null}
              <br />
              ReportInput id: {score ? (score.get("uid") || "").substring(0, 8) : null}
            </p>
          ) : null*/}
        </div>
      }
    >
      <Octicon name="info" className="ml-2" />
    </Tooltip>
  );
}

function NumSubDocs({ subDocs }) {
  let docNumStr = subDocs ? (subDocs.length ? `(${subDocs.length} docs) | ` : "") : "";
  if (!docNumStr) {
    return null;
  }
  return <span>{docNumStr}</span>;
}

const showConversionFactors = (e, props) => {
  e.preventDefault();
  let text = "";
  let { conversionFactor } = props;
  if (!conversionFactor) {
    alert("No conversion factor found");
  }

  let fromMetric = conversionFactor.getInPath("metric.name");
  let fromUnit = conversionFactor.getInPath("metric.unit");
  let toUnit = conversionFactor.get("toUnit");
  let factor = conversionFactor.get("factor");

  text += `${fromMetric}:\n`;
  text += `${fromUnit} -> ${toUnit} = ${factor}\n\n`;
  alert(text);
};

const showChildConversions = (e, props) => {
  e.preventDefault();
  let { childConversions: conversions } = props;
  let text = "Conversions from sub-metrics:\n\n";
  for (let conversion of conversions) {
    text += `${conversion.getInPath("fromMetric.name")}:\n`;
    text += `${conversion.get("inputValue")} ${conversion.getInPath("fromMetric.unit")} -> ${conversion.get(
      "convertedValue"
    )} ${conversion.get("toUnit")}\n`;
    text += `(conversion factor: ${conversion.get("conversionFactor")})\n\n`;
  }
  alert(text);
};

const getDropDownLinks = (props) => {
  let { parentScore, score, hasChildren, calculated, toggleCalculated, commit } = props;
  let disableManualEntry = score?.getInPath("meta_json.disable_manual_entry");
  let disableManualEntryForAll = commit?.getInPath("meta_json.disable_manual_entry");

  let thisItems = [];
  // Give the user the option to input manually
  if (calculated) {
    // if (!disableManualEntry && !disableManualEntryForAll) {
    //   thisItems.push({ name: "Manual Entry", onClick: toggleCalculated });
    // }
    thisItems.push({
      name: "Show derivation from sub-metrics",
      onClick: (e) => showChildConversions(e, props),
    });
  }

  // Toggle to calculate from children again
  if (!calculated && hasChildren) {
    thisItems.push({
      name: "Calculate from children",
      onClick: toggleCalculated,
    });
  }

  // Show conversion factors to parent
  if (parentScore) {
    thisItems.push({
      name: "Show conversion factors to parent",
      onClick: (e) => showConversionFactors(e, props),
    });
  }

  return thisItems;
};

function RowAdminDropdown({ dropDownLinks }) {
  if (!dropDownLinks?.length) {
    return null;
  }
  return (
    <div style={{ cursor: "pointer" }} className="p-0 m-0">
      <BSBtnDropdown links={dropDownLinks} header={""} floatStyle={"btn btn-sm btn-link p-0 m-0"} />
    </div>
  );
}

function StateSaved({ isSaved }) {
  if (!isSaved) {
    return null;
  }
  return <Octicon name="check" />;
}

const fetchInputHistory = ({ baseMetricId, commitId, trackerId, setValues }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

  let formData = new FormData();
  formData.append("type", "INPUT_VALUE_HISTORY");
  formData.append(
    "payload",
    JSON.stringify({
      num_items: 6,
      commitId,
      baseMetricId,
    })
  );
  fetch.updateFetchParams({
    body: formData,
    postSuccessHook: (data) => {
      // error handling if an error is received, it will be an object and .reverse() will cause an error.
      if (data?.payload?.errors) {
        console.log("Error in response from INPUT_VALUE_HISTORY", data);
        setValues(Traec.Im.List());
      } else {
        console.log("Got response from INPUT_VALUE_HISTORY", data);
        setValues(Traec.Im.fromJS(data?.payload).reverse());
      }
    },
  });

  fetch.dispatch();
};

function MetricValueHistory(props) {
  let { unit, baseMetricId, commitId, trackerId } = props;

  let [values, setValues] = useState(Traec.Im.List());

  console.log("Showing MetricValueHistory with", trackerId, commitId, baseMetricId);

  useEffect(() => {
    fetchInputHistory({ ...props, setValues });
  }, []);

  if (!values.size) {
    return null;
  }

  let data = {
    labels: values.map((i) => i.get("period_str")).toJS(),
    datasets: [
      {
        data: values.map((i) => i.get("value")).toJS(),
        label: "",
        backgroundColor: "#b3d9ff",
      },
    ],
  };

  let chartData = {
    type: "bar",
    options: {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: unit,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
    data,
  };

  console.log("Rendering MetricValueHistory with data", chartData);

  return (
    <ErrorBoundary>
      <div>
        <ChartJSWrapper chartData={chartData} />
      </div>
    </ErrorBoundary>
  );
}

function PastInputModal({ score, commit, tracker }) {
  let MODAL_ID = "ReportMetricHistoryModal-001";
  let baseMetricId = score?.getInPath("metric.uid");
  return (
    <span
      className="mt-1 p-0 btn btn-sm"
      style={{ cursor: "pointer" }}
      onClick={() => {
        clearModal(MODAL_ID);
        setTimeout(() => {
          setAndShowModal(MODAL_ID, {
            title: `Previous data for: ${score?.getInPath("metric.name")}`,
            body: (
              <MetricValueHistory
                unit={score.getInPath("metric.unit")}
                baseMetricId={baseMetricId}
                commitId={commit?.get("uid")}
                trackerId={tracker.get("uid")}
              />
            ),
          });
        }, 250);
      }}
    >
      <Octicon name="graph" />
    </span>
  );
}

export function RowAdminPanel(props) {
  let { subDocs, isSaved, conversionFactor, disableInputs } = props;
  if (disableInputs) {
    return (
      <div className="m-0 p-0 text-nowrap d-flex align-items-center justify-content-center">
        <PastInputModal {...props} />
      </div>
    );
  }
  let dropDownLinks = getDropDownLinks(props);
  return (
    <div className="m-0 p-0 text-nowrap d-flex align-items-center justify-content-center">
      <NumSubDocs subDocs={subDocs} />
      <StateSaved isSaved={isSaved} />
      <PastInputModal {...props} />
      {dropDownLinks?.length && isSaved ? "|" : null} <MetricAlerts conversionFactor={conversionFactor} />
      {/* The dropdown (cog) in reports below is being hidden because it does not provide value and takes space.
       <RowAdminDropdown dropDownLinks={dropDownLinks} /> */}
    </div>
  );
}

export function NotRequiredMsg({ dueDate, freq_num, freq_unit }) {
  return (
    <span>
      Due every {freq_num} {freq_unit}. Next due: {dueDate.format("Do MMM YY")}
    </span>
  );
}

function NotRequiredCells(props) {
  return (
    <td colSpan={4} style={{ verticalAlign: "middle" }} className="border-0">
      <NotRequiredMsg {...props} />
    </td>
  );
}

export const isNotInFrequency = ({ value, isRequiredPeriodically }) => {
  return !value && isRequiredPeriodically && !isRequiredPeriodically.dueThisReport;
};

function RowInputCells(props) {
  let {
    score,
    isRequiredPeriodically,
    value,
    ValueInputComponent,
    valueColSpan,
    commentColSpan,
    hideUnits,
    hideNoReport,
  } = props;

  ValueInputComponent = ValueInputComponent || ReportValueInputCell;

  if (isNotInFrequency(props)) {
    return <NotRequiredCells {...isRequiredPeriodically} />;
  }

  return (
    <CellSpanErrorBoundary colSpan={4}>
      <MetricUnitCell score={score} hide={hideUnits} />
      <ValueInputComponent {...props} colSpan={valueColSpan || 1} />
      <ReportCommentCell {...props} colSpan={commentColSpan || 1} />
      <ReportNoInputCheckbox {...props} hide={hideNoReport} />
    </CellSpanErrorBoundary>
  );
}

export function MetricNameDescriptionCell(props) {
  let { score } = props;
  return (
    <td className="border-0">
      <MetricName {...props} />
      <RequiredStar score={score} />
      <MetricTooltip {...props} />
    </td>
  );
}

function MetricUnitCell({ score, hide }) {
  if (hide) {
    return null;
  }
  return <td className="border-0">{score.getInPath("metric.unit")}</td>;
}

function ValueCommentRow(props) {
  let { score, rowStyle, calculated, noReport, exclSubNodes, value } = props;

  let highlight = (score.get("period") || -1) > 0;
  let _noReport = calculated ? false : noReport;

  let inputType = score.getInPath("meta_json.input_type") || "standard";
  let isBool = inputType == "boolean";
  let ValueInputComponent = isBool ? BooleanToggleCell : ReportValueInputCell;

  if (exclSubNodes == undefined && score.getInPath("meta_json.hideChildrenIfNullOrZero")) {
    exclSubNodes = value ? exclSubNodes : true;
  }

  // As per ASS-1457 - hide input cells if requested in meta_json
  let hideInputCells = score?.getInPath("meta_json.hideInputCells");
  if (hideInputCells) {
    return (
      <React.Fragment>
        <tr style={rowStyle?.toJS()} className={highlight ? "font-weight-bold" : ""}>
          <MetricNameDescriptionCell {...props} />
          <CellSpanErrorBoundary colSpan={5}>
            <td className="border-0"></td>
            <td className="border-0"></td>
            <td className="border-0"></td>
            <td className="border-0"></td>
            <td className="border-0"></td>
          </CellSpanErrorBoundary>
        </tr>
        <RowErrorBoundary>
          <SubNodes {...props} parentNoReport={_noReport} hide={exclSubNodes} />
        </RowErrorBoundary>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <tr style={rowStyle?.toJS()} className={highlight ? "font-weight-bold" : ""}>
        <MetricNameDescriptionCell {...props} />
        <RowInputCells
          {...props}
          ValueInputComponent={ValueInputComponent}
          valueColSpan={isBool ? 2 : 1}
          commentColSpan={isBool ? 2 : 1}
          hideUnits={isBool}
          hideNoReport={isBool}
        />
        <td className="border-0 p-0 m-0">
          <MiniErrorBoundary>
            <RowAdminPanel {...props} />
          </MiniErrorBoundary>
        </td>
      </tr>
      <RowErrorBoundary>
        <SubNodes {...props} parentNoReport={_noReport} hide={exclSubNodes || isNotInFrequency(props)} />
      </RowErrorBoundary>
    </React.Fragment>
  );
}

function SearchChildrenRow(props) {
  return (
    <RowErrorBoundary>
      <RowErrorBoundary>
        <ValueCommentRow {...props} exclSubNodes={true} />
      </RowErrorBoundary>
      <RowErrorBoundary>
        <SearchChildRow {...props} hide={isNotInFrequency(props)} />
      </RowErrorBoundary>
    </RowErrorBoundary>
  );
}

function SelectChildrenRow(props) {
  let { rowStyle, disableInputs } = props;
  return (
    <RowErrorBoundary>
      <RowErrorBoundary>
        <tr style={rowStyle?.toJS()} className={""}>
          <MetricNameDescriptionCell {...props} />
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="border-0"></td>
        </tr>
      </RowErrorBoundary>
      <RowErrorBoundary>
        <SelectChildRow {...props} hide={isNotInFrequency(props)} />
      </RowErrorBoundary>
    </RowErrorBoundary>
  );
}

function SelectTwinChildrenRow(props) {
  return (
    <RowErrorBoundary>
      <RowErrorBoundary>
        <ValueCommentRow {...props} rowStyle={Traec.Im.Map({ display: "none" })} exclSubNodes={true} />
      </RowErrorBoundary>
      <RowErrorBoundary>
        <SelectTwinChildRow {...props} />
      </RowErrorBoundary>
    </RowErrorBoundary>
  );
}

const getRowColor = ({ value, commit }) => {
  if (!value || !commit) {
    return null;
  }
  let color = null;
  let lastUpdate = value.getInPath("meta_json.lastUpdatedOn") || value.get("created");
  let history = commit.getInPath("meta_json.history") || Traec.Im.List();
  let non_hold_history = history.filter((i) => !(i.getInPath("comment") || "").startsWith("On hold"));
  let firstCommit = Traec.Im.fromJS(non_hold_history.first() || Traec.Im.Map()).get("updateOn");

  // The first console.log is when the rows are being colored pink.
  // if (lastUpdate && firstCommit) {
  //   if (Moment(lastUpdate).diff(Moment(firstCommit)) > 0) {
  //     color = chroma("#bf80ff").brighten(2).hex();
  //     console.log(
  //       Moment(firstCommit).format("HH:mm ddd DD MMM YYYY"),
  //       "< firstCommit (row color is being updated) lastUpdate >",
  //       Moment(lastUpdate).format("HH:mm ddd DD MMM YYYY")
  //     );
  //   } else {
  // The second console.log the rows are not colored pink but lastUpdate seems to be before firstCommit
  //     console.log(
  //       Moment(firstCommit).format("HH:mm ddd DD MMM YYYY"),
  //       "< first commit (no row color update) lastUpdate",
  //       Moment(lastUpdate).format("HH:mm ddd DD MMM YYYY"),
  //       "rowStyle difference >",
  //       Moment(lastUpdate).diff(Moment(firstCommit))
  //     );
  //   }
  // }

  return color;
};

const isString = (v) => typeof v === "string" || v instanceof String;

const floatsEq = (a, b) => {
  if (isNullOrUndefined(a) && isNullOrUndefined(b)) {
    return true;
  }
  if (a === "" && b === "") {
    return true;
  }
  if (typeof a !== typeof b) {
    return false;
  }
  if (isString(a) && isString(b)) {
    return a == b;
  }
  return Math.abs(a - b) < 1e-6;
};

const dispatchSave = (props) => {
  let {
    method,
    trackerId,
    commitId,
    scoreId,
    inputValueId,
    path,
    calculated,
    value,
    comment,
    noReport,
    childConversions,
    onSuccess,
  } = props?.toJS();

  //
  let fetch = new Traec.Fetch("tracker_commit_score_value", method, {
    trackerId,
    commitId,
    scoreId,
    inputValueId,
  });

  let _value = isNaN(value) || value === "" ? null : value;

  fetch.updateFetchParams({
    preFetchHook: () => ({
      value: _value,
      comment: comment,
      meta_json: {
        lastInput: new Date(),
        noReport: noReport,
        conversions: childConversions,
        calculated: calculated,
      },
    }),
    postSuccessHook: onSuccess,
    throttleTimeCheck: 1,
  });

  fetch.dispatch();
};

class ReportScoreRow extends React.Component {
  constructor(props) {
    super(props);

    let { path, calculated, value, valueId, initComment, initValue, initNoInput, disableInputs } = props;

    this.state = {
      calculated: calculated,
      // Information on if we are using the saved data
      isSaved: true,
      isValid: true,
      valueId: valueId,
      // State holders for input values
      value: isNullOrUndefined(initValue) ? "" : initValue,
      comment: isNullOrUndefined(initComment) ? "" : initComment,
      noReport: (isNullOrUndefined(initNoInput) ? false : initNoInput) || false,
      // Keep a record of the conversions from the children that were summed
      childConversions: [],
      // Form for inputting conversion factors from children to parent
      convFactorForm: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
      // Disable only the value input
      disableValueInput: initNoInput || false,
    };
    //console.log("CONSTRUCTING REPORT ROW", initValue, this.state.value)

    this.toggleCalculated = this.toggleCalculated.bind(this);
    this.addConversionFactor = this.addConversionFactor.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleNoReportChange = this.handleNoReportChange.bind(this);
    this.saveToRedux = this.saveToRedux.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      path,
      score,
      initValue,
      initComment,
      initNoInput,
      valueId,
      parentNoReport,
      hasParentConversion,
      calculated,
      calculatedValue,
      disableInputs,
    } = this.props;
    let { value, isSaved, noReport } = this.state;

    let hasChangedNoReportHere = noReport !== prevState.noReport;
    if (!hasChangedNoReportHere && parentNoReport && !noReport) {
      console.log(
        "NO REPORT CHANGED ON PARENT",
        path,
        parentNoReport,
        noReport,
        prevState.noReport,
        hasChangedNoReportHere
      );
      this.handleNoReportChange({ target: { checked: true } });
    }

    if (!hasParentConversion) {
      this.setConversionInRedux();
    }

    // If all of the children are "noreport" then set this to noreport also
    let _noReport = calculatedValue == "noreport";
    if (_noReport) {
      console.log("Handling update with noReport", path);
      if (prevState.noReport !== true) {
        // console.log("setState being called A", this.props.path)
        this.setState({
          isSaved: false,
          noReport: true,
          value: "",
        });
      }
    } else {
      // Has the calculated value changed?
      let calculatedValueUpdated =
        (!floatsEq(calculatedValue, initValue) &&
          !isNullOrUndefined(calculatedValue) &&
          !floatsEq(prevState.value, calculatedValue)) ||
        noReport;
      let shouldUpdateState =
        !disableInputs && calculated && calculatedValueUpdated && !!isSaved && !floatsEq(calculatedValue, value);

      // Updated the value of this with the calculated value
      if (shouldUpdateState) {
        let newValue = isNullOrUndefined(calculatedValue) ? "" : parseFloat(calculatedValue);
        newValue = isNaN(newValue) ? "" : newValue;
        this.setState({
          isSaved: false,
          value: newValue,
          noReport: false,
        });
      }
    }

    // Reset if we have a new value object (from a post command)
    if (prevProps.valueId !== valueId) {
      this.setState({
        value: isNullOrUndefined(initValue) ? "" : initValue,
        comment: isNullOrUndefined(initComment) ? "" : initComment,
        disableValueInput: initNoInput || false,
      });
    }

    let requiresSaveToRedux = (prevState.value !== value && !isSaved && calculated) || prevState.noReport !== noReport;

    if (!disableInputs && requiresSaveToRedux) {
      this.setConversionInRedux();
      this.saveToRedux();
    }

    if (!isSaved) {
      this.validateServerSave();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      hasDiff(this.props, nextProps, null) || // ,`ReportScoreRow props ${this.props.path}`
      hasDiff(this.state, nextState, null) // `ReportScoreRow state ${this.props.path}`
    );
  }

  /**********************
   ACTIONS
   **********************/

  toggleCalculated(e) {
    e.preventDefault();
    // console.log("setState being called D", this.props.path)
    this.setState({ calculated: !this.state.calculated, isSaved: false }, () => this.saveToServer());
  }

  addConversionFactor(e) {
    e.preventDefault();
    let { convFactorDetails } = this.props;
    let { convFactRef, convFactorMap } = convFactorDetails;
    let commitId = convFactRef.getIn(["latest_commit", "uid"]);
    let trackerId = convFactRef.get("tracker");

    let fetch = new Traec.Fetch("tracker_commit_convfactor", "post", {
      trackerId,
      commitId,
    });
    // console.log("setState being called E", this.props.path)
    this.setState({ convFactorForm: fetch.params });

    fetch.toggleForm();
  }

  validateServerSave() {
    // See if the value in state has changed (by saving)
    let { value: _value, path } = this.props;
    let { value, comment, noReport, isSaved } = this.state;
    if (_value) {
      // Get the saved value
      let savedValue = _value.get("value");
      savedValue = savedValue === null ? "" : savedValue;
      // Check equality
      let equalValues = value === savedValue;
      let equalComments = comment === _value.get("comment");
      let equalNoReport = noReport === _value.getInPath("meta_json.noReport");
      if (equalValues && equalComments && equalNoReport) {
        this.setState({ isSaved: true });
      }
    }
  }

  saveToServer() {
    let { userName, path, score, value, valueId: inputValueId, trackerId, commitId, childConversions } = this.props;
    let scoreId = score.get("uid");

    // Determine the method that we should use (overwrite existing or post new input)
    let method = inputValueId ? "put" : "post";
    if (method === "put" && inputValueId && value) {
      // Post a new value if we are a different user
      if (value.getInPath("creator.username") !== userName) {
        method = "post";
      }
      // Post a new value if the time since last input is > 30 minutes
      if (method === "put") {
        let created = Moment(new Date(value.get("created") + "Z"));
        let now = Moment(new Date());
        if (now.diff(created, "minutes") > 30) {
          method = "post";
        }
      }
    }

    dispatchSave(
      Traec.Im.fromJS({
        method,
        trackerId,
        commitId,
        scoreId,
        inputValueId,
        path,
        calculated: this.state.calculated,
        value: this.state.value,
        comment: this.state.comment,
        noReport: this.state.noReport,
        onSuccess: (data) => {
          this.setState({ isSaved: true });
        },
        childConversions,
      })
    );
  }

  saveToRedux() {
    let { isSaved, value: stateValue, comment } = this.state;
    let { value, disableInputs, path } = this.props;

    if (isSaved || disableInputs) {
      return null;
    }

    stateValue = isNaN(stateValue) ? null : stateValue;

    // Save this to the server if the input/value has changed
    if (!value || value.get("value") !== stateValue || value.get("comment") !== comment) {
      this.saveToServer();
    }
  }

  setConversionInRedux() {
    let { value, noReport } = this.state;
    let { dispatch, score, conversionFactor, commitId, path } = this.props;

    if (!conversionFactor || !conversionFactor.get("toUnit")) {
      return null;
    }

    let parentConversion = get_conversion({ score, value, conversionFactor, noReport });

    if (parentConversion) {
      parentConversion.fromPath = path;
    }
    let parentPath = path.substring(0, path.length - 7);
    let itemPath = `childConversions.${commitId}.${parentPath}.${path}`;

    console.log("Saving conversion to parent in store", itemPath, parentConversion);
    dispatch(setUIItems(Traec.Im.fromJS(parentConversion), { itemPath }));
  }

  handleInputChange(e) {
    let value = e.target.value;
    console.log("Handling input value change", value);
    if (!this.state.calculated) {
      // console.log("setState being called G", this.props.path)
      this.setState({
        isSaved: false,
        value,
      });
    }
  }

  handleCommentChange(e) {
    //console.log("Handling comment change")
    e.preventDefault();
    if (!this.state.calculated) {
      // console.log("setState being called H", this.props.path)
      this.setState({
        isSaved: false,
        comment: e.target.value,
      });
    }
  }

  handleNoReportChange(e) {
    let newState = {
      isSaved: false,
      noReport: e.target.checked,
      disableValueInput: e.target.checked,
    };
    if (e.target.checked) {
      newState.value = "";
      //newState.comment = "";
    }
    console.log("TOGGLING NO REPORT SETTING NEW STATE", newState);
    // console.log("setState being called I", this.props.path)
    this.setState(newState);
  }

  handleBlur(e) {
    e.preventDefault();
    let { categoryPath, updateInputErrors, score, currentReportingPeriod } = this.props;

    console.log("HANDLING BLUR");
    this.setConversionInRedux();

    let value = parseFloat(this.state.value);
    value = isNaN(value) || value == null ? this.state.value : value;
    if (value === "0") {
      value = 0;
    } else if (value === "" || value === null || value === undefined) {
      value = null;
    }

    let isValid = typeof value !== "string" || value === 0 || value == null;
    if (!this.state.calculated) {
      // console.log("setState being called J", this.props.path)
      this.setState(
        {
          isSaved: false,
          value: value,
          isValid: isValid,
        },
        () => {
          return isValid ? this.saveToRedux() : null;
        }
      );
    }

    updateInputErrors(
      score.getInPath("metric.name"),
      value,
      isRequiredInPeriod(score, currentReportingPeriod),
      categoryPath
    );

    //this.saveToRedux();
  }

  /**********************
   RENDER METHODS
   **********************/

  render() {
    //console.log("CALLING render on ReportScoreRow");

    let { score, commit, hidden, indentLevel, value: propsValue, InputComponent, disableInputs } = this.props;
    let { calculated, noReport, value: stateValue, disableValueInput, isSaved, comment } = this.state;

    let isHidden = hidden == undefined ? score.getInPath("meta_json.hidden") : hidden;
    let rowStyle = { backgroundColor: getRowColor({ value: propsValue, commit }) };
    let value = stateValue; // || (propsValue ? propsValue.get("value") : null);
    if (isHidden) {
      rowStyle.display = "none";
    }

    // Get the component to render for this row - and extra props to pass to it (from state)
    let RowComponent = InputComponent || getRowComponent(score);
    let _props = {
      ...this.props,
      indentLevel: isHidden ? indentLevel - 1 : indentLevel,
      calculated,
      noReport,
      rowStyle: Traec.Im.fromJS(rowStyle),
      disableInputs,
      disableValueInput,
      value,
      comment,
      //isSaved,
      toggleCalculated: this.toggleCalculated,
      handleInputChange: this.handleInputChange,
      handleCommentChange: this.handleCommentChange,
      handleNoReportChange: this.handleNoReportChange,
      handleBlur: this.handleBlur,
    };

    return (
      <RowErrorBoundary>
        <RowComponent {..._props} />
      </RowErrorBoundary>
    );
  }
}

export const isRequiredInPeriod = (score, reportingPeriod) => {
  let isRequired = score.get("required", false);
  if (!isRequired) {
    return false;
  }
  let isRequiredPeriodically = isRequiredOnFreq(score, reportingPeriod);
  if (!isRequiredPeriodically) {
    return isRequired;
  }
  let { dueThisReport } = isRequiredPeriodically;
  if (dueThisReport) {
    return true;
  }
  return false;
};

const isDueData = (score, prev_date, cur_date, isDue) => {
  let data = {
    dueThisReport: isDue,
    from_date: Moment(prev_date.toISOString()),
    dueDate: Moment(cur_date.toISOString()),
    freq_unit: score.get("freq_unit"),
    freq_num: score.get("freq_num"),
  };
  return data;
};

export const isRequiredOnFreq = (score, currentReportingPeriod) => {
  if (!score || !currentReportingPeriod) {
    return null;
  }
  let freq_unit = score.get("freq_unit");
  let freq_num = score.get("freq_num");
  let from_date = score.get("from_date");
  if (!freq_unit | !freq_num | !from_date) {
    return null;
  }
  // Convert the quarters into 3* months
  if (freq_unit === "quarters") {
    freq_unit = "months";
    freq_num = freq_num * 3;
  }
  // Start at from_date and step up until we are past the current reporting period (or now)
  let cur_date = Moment(from_date).startOf("day");
  let prev_date = Moment(cur_date);
  let startDate = Moment(currentReportingPeriod.get("startDate"));
  let endDate = Moment(currentReportingPeriod.get("endDate"));

  // Look forwards 100 periods from the start
  for (let i = 0; i < 100; i++) {
    let _date = Moment(cur_date).add(-7, "days");
    if (_date.isAfter(endDate)) {
      return isDueData(score, prev_date, cur_date, false);
    }
    if (_date.isBetween(startDate, endDate)) {
      return isDueData(score, prev_date, cur_date, true);
    }
    prev_date = Moment(cur_date);
    cur_date = cur_date.add(freq_num, freq_unit);
  }
  return isDueData(score, prev_date, cur_date, false);
};

const sumChildConversions = (state, commitId, path) => {
  let conversions = (state.getInPath(`ui.childConversions.${commitId}.${path}`) || Traec.Im.Map()).toList();

  // Strip out null conversions and ensure all are immutable
  conversions = conversions.filter((i) => i).map((i) => Traec.Im.fromJS(i));

  //console.log("Summing child conversions for", commitId, path, conversions.toJS());

  if (conversions.size && conversions.every((item) => item?.get("value") == "noreport")) {
    console.log("Setting parent to noReport because all children are noReport", conversions.toJS());
    return "noreport";
  }

  let totalValue = null;
  for (let item of conversions) {
    if (!isNullOrUndefined(item.get("convertedValue"))) {
      totalValue += item.get("convertedValue");
    }
  }

  return totalValue;
};

export const nestBaseMetric = (state, score) => {
  if (!state || !score) {
    return null;
  }
  // If the baseMetric is not already nested in the score then do it now
  let baseMetricId = score.getInPath("metric.uid");
  if (!baseMetricId) {
    baseMetricId = score.get("metric");
    if (baseMetricId) {
      return score.set("metric", state.getInPath(`entities.baseMetrics.byId.${baseMetricId}`));
    }
  }
  return score;
};

const isCalculated = (score, value, hasChildren) => {
  if (score.getInPath("meta_json.calculated") === false) {
    return false;
  } else if (value && value.getInPath("meta_json.calculated") !== undefined && hasChildren) {
    return value.getInPath("meta_json.calculated");
  }
  // By default the children are calculated (if not )
  return hasChildren;
};

const getCalculatedValue = (state, commitId, path) => {
  let value = sumChildConversions(state, commitId, path);
  value = isNullOrUndefined(value) ? "" : value;
  //console.log("Calculated value at path", path, value);
  return value;
};

const mapStateToProps = (state, ownProps) => {
  let { path, commitNodes, convFactorDetails, commitId, currentReportingPeriod } = ownProps;

  // Get this score
  let score = nestBaseMetric(state, getNodeFromPath(state, path, commitNodes));
  let scoreId = score.get("uid");
  let baseMetricId = score.getInPath("metric.uid");

  // Get the parent and conversion factor to parent
  let parentScore = nestBaseMetric(state, getParentNodeFromPath(state, path, commitNodes, "metricScores"));
  let conversionFactor = getConversionFactor({ score, parentScore, convFactorDetails });
  let hasParentConversion = state.hasIn(["ui", "childConversions", commitId, path.substring(0, path.length - 7), path]);

  // Get the children
  let childScores = getPathChildren(state, path, commitNodes, "metricScores").filter((i) => i);
  let hasChildren = childScores.size > 0;

  // Get the values for this report
  let values = commitId
    ? state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues.${baseMetricId}.values`)
    : null;
  let value = values ? getLatestValue(values) : null;
  let valueId = value ? value.get("uid") : null;

  // Get if this metric is required
  let isRequired = score ? score.get("required", false) : false;
  let isRequiredPeriodically = isRequiredOnFreq(score, currentReportingPeriod);

  // Get any initial values (loaded from state)
  let initNoInput = value ? value.getInPath("meta_json.noReport") : false;
  let initValue = value ? value.get("value") : null;
  let initComment = value ? value.get("comment") : "";
  //console.log("MapStateToProps got initial value", path, initValue)

  let userName = state.getInPath("auth.user.username");
  let calculated = isCalculated(score, value, hasChildren);
  let calculatedValue = calculated ? getCalculatedValue(state, commitId, path) : null;

  return {
    score,
    parentScore,
    conversionFactor,
    hasParentConversion,
    calculatedValue,
    hasChildren,
    calculated,
    value,
    valueId,
    isRequired,
    isRequiredPeriodically,
    initNoInput,
    initValue,
    initComment,
    userName,
  };
};

export default connect(mapStateToProps)(ReportScoreRow);
